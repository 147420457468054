import { FBox, Text, TextVariants } from '@retrain-ai/ui-design';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MobileLogin.style';
import IsolationModeImage from '../../images/isolation-mode.svg';
import { ReactComponent as CloseIcon } from '../../images/close-icon.svg';
import { IMobileLoginProps } from './types';
import { useRtl } from '../../RTL';
import { useCustomization } from '../../components/customization/customization';

export const MobileLogin: React.FC<IMobileLoginProps> = ({ onClose }) => {
  const { classes: s, cx } = useStyles();
  const { t } = useTranslation();
  const { mobileLoginContent } = useCustomization();
  const rtl = useRtl();

  return (
    <FBox alignItems="center" className={s.root}>
      <FBox flexDirection="column" alignItems="center" className={s.wrapper}>
        <FBox className={cx(s.closeIcon, rtl ? s.closeIconLeft : s.closeIconRight)} onClick={onClose}>
          <CloseIcon />
        </FBox>
        <img src={IsolationModeImage} alt="fdsfs" className={s.image} />
        <Text variant={TextVariants.BigTitleBold} className={s.firstParagraph}>
          {mobileLoginContent ? mobileLoginContent.firstParagraph : t('mobile-access-text.first-paragraph')}
        </Text>
        {mobileLoginContent ? (
          <>
            <Text
              variant={TextVariants.RunningText}
              dangerouslySetInnerHTML={{ __html: mobileLoginContent.secondParagraph ?? '' }}
            />
            <br />
            <Text
              variant={TextVariants.RunningText}
              dangerouslySetInnerHTML={{ __html: mobileLoginContent.thirdParagraph ?? '' }}
            />
          </>
        ) : (
          <Text variant={TextVariants.RunningText}>{t('mobile-access-text.second-paragraph')}</Text>
        )}
      </FBox>
    </FBox>
  );
};
